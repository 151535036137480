<template>
  <div id="app">
    <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
  <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload,
    }
  },
  data () {
    return {
      // 标记路由是否显示，解决点击一级菜单刷新页面
      isShow: true,
      // windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      // windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
    }
  },
  created(){
    this.browserRedirect()
  },  
  mounted () {
    let _this=this;
    window.onresize=()=>{
      _this.browserRedirect()
    }    
  },
  methods:{
    browserRedirect(){
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        let pageurl=window.location.href;
            let hostname=window.location.hostname;
            let pathname=window.location.pathname;
            window.location.href = "https://student.sijiedu.com/studentmanagemobile/#/";
      }
    }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  
}
html {
  margin: 0 auto;
  height: 100%;
  min-height: 100%;
}
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: "Microsoft YaHei", "微软雅黑", "宋体", Tahoma, Arial, sans-serif;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5d5d5d;
  width: 100%;
  background: #f1f2fa;
  min-height: 100%;
  font-size: 16px;
  overflow-x: hidden;
}
a{
  color:#7276fe;
}
.flex {
  display: flex;
  align-items: center;
}
.flexBtw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.linkbtn {
  text-decoration: none;
  color: #6f72ff;
}
.handle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #6f72ff;
  cursor: pointer;
  img {
    width: 17px;
    margin-left: 5px;
  }
}
.bold {
  font-weight: bold;
}
.color1 {
  color: #6f72ff;
}
.bgpurple {
  background: #f1f2fa;
}
.bgfff {
  background: #fff;
}
.el-button--primary {
  background: #6f72ff !important;
  border-color: #6f72ff !important;
  width: 140px;
}
.el-breadcrumb {
  font-size: 16px !important;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.el-breadcrumb .el-breadcrumb__inner.is-link {
  color: #929292;
  font-weight: normal;
}
.el-table__header {
  font-size: 18px;
  color: #5d5d5d;
  th {
    font-weight: normal !important;
  }
}
.el-table {
  font-size: 16px !important;
  border-radius: 5px;
  .el-table__cell {
    padding: 17px 0 !important;
  }
  .even {
    background: #f1f2fa;
  }

  .odd {
    background: #fff;
  }
}
.el-tabs--card > .el-tabs__header {
  border: none !important;
  margin: 0;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}
.el-tabs__item {
  color: #929292 !important;
  background: #f1f2fa;
  height: 45px !important;
  line-height: 44px !important;
  font-size: 18px !important;
  border: 1px solid #e9e9ec !important;
  border-bottom: none !important;
  border-radius: 5px 5px 0 0;
}
.el-tabs__item.is-active {
  color: #fff !important;
  background: #6f72ff;
  font-size: 20px !important;
  border: none !important;
}
.el-pagination,
.el-pagination .btn-next,
.el-pagination .btn-prev {
  color: #929292 !important ;
}
.el-pager li {
  font-size: 16px !important;
  font-weight: normal;
}
.el-pager li.active,
.el-pager li:not(.active):hover {
  color: #6f72ff !important;
}
.el-pagination span:not([class*="suffix"]) {
  font-size: 16px !important ;
}
.errortips{
  text-align: center;
  padding-top: 20px;
  font-size: 18px;
}
</style>
