export const homeChildren = [
  {
    path: '/class',
    name: 'class',
    component: resolve => require(['@/views/class/index'], resolve),
    meta: {
      requiresAuth: true,
      title: '我的班级',
      menu: true
    }
  },
  {
    path: '/class/classdetail',
    name: 'class_detail',
    component: resolve => require(['@/views/class/detail'], resolve),
    meta: {
      requiresAuth: true,
      title: '课程详情',
      menu: false
    }
  },
  {
    path: '/class/coursemater',
    name: 'class_coursemater',
    component: resolve => require(['@/views/class/coursemater'], resolve),
    meta: {
      requiresAuth: true,
      title: '课程资料',
      menu: false
    }
  },
  {
    path: '/class/achievement',
    name: 'class_achievement',
    component: resolve => require(['@/views/class/achievement'], resolve),
    meta: {
      requiresAuth: true,
      title: '我的成绩',
      menu: false
    }
  },
  {
    path: '/coursemater',
    name: 'coursemater',
    component: resolve => require(['@/views/courseMaterials/index'], resolve),
    meta: {
      requiresAuth: true,
      title: '课程资料',
      menu: true,
      keepAlive: true
    }
  },
  {
    path: '/coursemater/material',
    name: 'coursemater_material',
    component: resolve => require(['@/views/courseMaterials/coursematerial'], resolve),
    meta: {
      requiresAuth: true,
      title: '资料',
      menu: false
    }
  },
  {
    path: '/coursemater/coursevideo',
    name: 'coursemater_coursevideo',
    component: resolve => require(['@/views/courseMaterials/coursevideo'], resolve),
    meta: {
      requiresAuth: true,
      title: '视频',
      menu: false
    }
  },
  {
    path: '/coursemater/courseupload',
    name: 'coursemater_courseupload',
    component: resolve => require(['@/views/courseMaterials/courseupload'], resolve),
    meta: {
      requiresAuth: true,
      title: '上传',
      menu: false
    }
  },
  {
    path: '/coursemater/courseuploadresult',
    name: 'coursemater_courseuploadresult',
    component: resolve => require(['@/views/courseMaterials/courseuploadresult'], resolve),
    meta: {
      requiresAuth: true,
      title: '上传',
      menu: false
    }
  },
  {
    path: '/achievement',
    name: 'achievement',
    component: resolve => require(['@/views/achievement/index'], resolve),
    meta: {
      requiresAuth: true,
      title: '我的成绩',
      menu: true
    }
  },
  {
    path: '/questionset',
    name: 'questionset',
    component: resolve => require(['@/views/questionSet/index'], resolve),
    meta: {
      requiresAuth: true,
      title: '错题集',
      menu: true
    }
  },
  {
    path: '/questionset/questiondetail',
    name: 'questionset_detail',
    component: resolve => require(['@/views/questionSet/detail'], resolve),
    meta: {
      requiresAuth: true,
      title: '错题集',
      menu: false
    }
  },
  {
    path: '/comment',
    name: 'comment',
    component: resolve => require(['@/views/comment/index'], resolve),
    meta: {
      requiresAuth: true,
      title: '我的评语',
      menu: true
    }
  },
  {
    path: '/changePsw',
    name: 'changePsw',
    component: resolve => require(['@/views/userinfo/changePsw'], resolve),
    meta: {
      title: '修改密码',
      requiresAuth: true
    },
  },
  {
    path: '/bindstu',
    name: 'bindstu',
    component: resolve => require(['@/views/userinfo/bindstu'], resolve),
    meta: {
      title: '学生信息绑定',
      requiresAuth: true
    },
  },
  {
    path: '/editPerson',
    name: 'editPerson',
    component: resolve => require(['@/views/userinfo/editPerson'], resolve),
    meta: {
      title: '学生信息补全',
      requiresAuth: true
    },
  },
]
