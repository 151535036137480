import request from '../utils/request'
//登录
export function user_login (data) {
  return request({
    url: `/accountinfo/user_login`,
    method: 'post',
    params: data
  })
}
//二次登录
export function user_login_dfa (data) {
  return request({
    url: `/accountinfo/user_login_dfa`,
    method: 'post',
    params: data
  })
}
//注册用户
export function user_reg (data) {
  return request({
    url: `/accountinfo/user_reg`,
    method: 'post',
    params: data
  })
}
//用户注册-学生年级
export function sys_grade (data) {
  return request({
    url: `/accountinfo/sys_grade`,
    method: 'post',
    params: data
  })
}
//退出登录
export function user_logout (data) {
  return request({
    url: `/accountinfo/user_logout`,
    method: 'post',
    params: data
  })
}
//忘记密码
export function forget_pwd (data) {
  return request({
    url: `/accountinfo/forget_pwd`,
    method: 'post',
    params: data
  })
}
//修改密码
export function edit_pwd (data) {
  return request({
    url: `/accountinfo/edit_pwd`,
    method: 'post',
    params: data
  })
}
//发送短信
export function send_sms (data) {
  return request({
    url: `/accountinfo/send_sms`,
    method: 'post',
    params: data
  })
}
//获取图片验证码
export function get_zifuchuan (data) {
  return request({
    url: `/accountinfo/get_zifuchuan`,
    method: 'post',
    params: data
  })
}
//绑定学生_第一步
export function bind_student_1 (data) {
  return request({
    url: `/accountinfo/bind_student_1`,
    method: 'post',
    params: data
  })
}
//绑定学生_第二步
export function bind_student_2 (data) {
  return request({
    url: `/accountinfo/bind_student_2`,
    method: 'post',
    params: data
  })
}
//获取学生信息
export function get_students_info (data) {
  return request({
    url: `/accountinfo/get_students_info`,
    method: 'post',
    params: data
  })
}
//修改默认学生
export function edit_default_student (data) {
  return request({
    url: `/accountinfo/edit_default_student`,
    method: 'post',
    params: data
  })
}
//解绑学生
export function unbound_student (data) {
  return request({
    url: `/accountinfo/unbound_student`,
    method: 'post',
    params: data
  })
}
//修改学生信息
export function edit_student_info (data) {
  return request({
    url: `/accountinfo/edit_student_info`,
    method: 'post',
    params: data
  })
}