import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Cookies from "js-cookie";
import {
  homeChildren
} from './homeChildren'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  // {
  //   path: '',
  //   name: 'login',
  //   redirect: '/login',
  //   component: resolve => require(['@/views/login'], resolve),
  // },
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login'], resolve),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: resolve => require(['@/views/register'], resolve),
    meta: {
      title: '注册',
    },
  },
  {
    path: '/forgetPsw',
    name: 'forgetPsw',
    component: resolve => require(['@/views/forgetPsw'], resolve),
    meta: {
      title: '忘记密码',
    },
  },
  {
    path: '/changepswlogin',
    name: 'changepswlogin',
    component: resolve => require(['@/views/changePsw'], resolve),
    meta: {
      title: '修改密码',
    },
  },
  {
    path: '',
    name: 'layout',
    component: resolve => require(['@/components/layout'], resolve),
    redirect: '/class',
    children: homeChildren
  },
  {
    path: '*',
    redirect: '/login',
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.requiresAuth) {
    const token = Cookies.get('stu_userId');
    let stuvuex = localStorage.getItem('stuvuex') ? JSON.parse(localStorage.getItem('stuvuex')) : null;
    let vuexint = stuvuex && stuvuex.user.stu_user_info ? true : false;
    if (token && token != '' && vuexint) {
      next()
    } else {
      store.commit("logoutcom", {})
      next('/login')
    }
  } else {
    next();
  }

})
export default router
