import { user_logout } from '@/api/user'
import router from '../../router/index'
import { setTimeout } from 'core-js';
import { Message } from 'element-ui'
import Cookies from "js-cookie";
const user = {
    state: {
        stu_user_info: [],
        stu_students_info: []
    },
    mutations: {
        logoutcom (state) {
            state.stu_user_info = null
            state.stu_students_info = null
            Cookies.remove('stu_userId')
            localStorage.removeItem('stuvuex')
        },
        /**登录成功后存储用户信息*/
        setUserInfo (state, data) {
            let prms = JSON.parse(data)
            if(prms.students_info && prms.students_info.length>0){
                prms.students_info.forEach((element) => {
                    if (element.Default == 1) {
                        state.stu_students_info = element
                    }
                });
            }
            state.stu_user_info = prms.user_info;
        },
        setStuInfo (state, data) {
            let prms = JSON.parse(data)
            state.stu_students_info = prms
        }
    },
    actions: {
        /**退出登录 */
        async logout ({ state,commit },str ) {
            let res = await user_logout();
            if (res.flag == '1') {
                setTimeout(() => {
                    router.push('/login');
                    if (str){  Message.success(str.tips); }
                    commit('logoutcom')
                }, 2000)
            } else {
                Message.error(res.msg);
            }
            return res
        }
    }
};
export default user