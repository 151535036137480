import axios from 'axios'
import { Message, Loading } from 'element-ui';
import store from '../store'
// import Cookies from "js-cookie";
const service = axios.create({
    validateStatus: function (status) {
        return status <= 500
    },
    withCredentials: true
});
let environment = ''
// http://tstudentapi.sijiedu.com 测试     http://studentapi.sijiedu.com 正式
let _baseURL = window.location.origin.indexOf('student.sijiedu.com')>= 0?'https://studentapi.sijiedu.com':''
service.defaults.baseURL = _baseURL;
let loading = null;
// export const testUrl = environment
/** 
 * 请求失败后的错误统一处理 
 * @param {Number} status 请求失败的状态码
 */
service.interceptors.request.use(config => {
    loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.8)'
    });
    return config
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '服务器异常，请联系管理员！'
    return Promise.resolve(error)
});
const errorHandle = (status, errMsg) => {
    if (errMsg) {
        // console.error(errMsg)
    }
    // 状态码判断
    switch (status) {
        case 400:
            return '400'
        case 403:
            return '403'
        case 404:
            return '404'
        case 405:
            return '405'
        case 500:
            return '500 Internal Server Error'
        case 502:
            return '502'
        case 503:
            return '服务不可用'
        default:
            if (errMsg) {
                // console.error('status----', status)
            }
            break
    }
}
service.interceptors.response.use(({ status, data }) => {
    let erroe = errorHandle(status)
    if (erroe) {
        Message.error(erroe);
        // setTimeout(() => {
        //     store.dispatch("logout")
        // }, 1000)
        loading.close();
    } else {
        if(data){
            if (data.flag == '-1') {
                // Message.error('登录失效');
                // store.dispatch("logout")
                store.dispatch("logout",{
                    tips: '登录失效,请重新登录'
                  });            
                return false
            }
            loading.close();
            return data
        }
    }
})

export default service;
